$red: #E65A45;
$redSec: #d4523a;
$gray: #B1B7B7;
$gray2: #6E7373;
$grayLight: #FAFBFB;

$specialColor: #151616;

$desktop: 1280px;
$smallDesk: 1024px;
$tablet: 992px;
$phone: 480px;


$redcolor: #E65A45;

@import 'settings/mixins';
@import 'settings/colors';
@import 'modules/seo_form';

.header{

  &-404{
    padding: 0;
    background: #030404;

    .wrapper{
      position: relative;
      margin: 0 auto;
    }
  }
}

.page-404 {
  position: relative;
  height: 100vh;

  .page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    min-height: 100%;
    background-image: url(/assets/templates/img/BG-black.jpg);
    background-repeat: repeat;
    background-position: center;
    padding-bottom: vw(160px);

    @include media(1024px){
      padding-bottom: 100px;
    }

    .wrapper{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .top{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 60px;

    @include mediamin(1280px){
      width: 80%;
      margin-top: vw(135px);
    }

    strong{
      font-size: vw(300px);
      font-weight: 400;
      color: #fff;
      line-height: 1;

      @include mediamin(1280px){
        font-size: vw(295px);
      }
    }
  }
  .bottom{
    width: 100%;

    p{
      font-size: 14px;
      line-height: 27px;
    }

    @include mediamin(1280px){
      max-width: 44%;

      .h2{
        font-size: vw(40px);
        margin-bottom: vw(20px);
      }

      p{
        font-size: vw(18px);
        line-height: vw(27px);
      }
    }
  }
  .circle_button{
    display: inline-block;
    overflow: visible;
    margin-left: -15px;
    margin-right: -15px;

    @include mediamin(1280px){
      width: vw(250px);
      height: vw(250px);
    }

    @include media(768px){
      margin-left: -5px;
      margin-right: -5px;
    }

    svg{
      fill: $redcolor;
    }
  }

  .buttons{
    margin-top: vw(80px);
    display: flex;
    width: 100%;
    justify-content: center;

    .button{
      width: vw(390px);
      height: vw(70px);
      font-weight: 500;
      font-size: vw(16px);
      line-height: vw(25px);
      max-width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      & + .button{
        margin-left: vw(30px);
      }

      svg{
        position: relative;
        left: inherit;
        top: inherit;
        transform: none;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        width: vw(28px);
        height: vw(28px);

        @include media(1024px){
          width: 28px;
          height: 28px;
        }
      }

      @include media(768px){
        width: 100%;
        max-width: 80%;
        font-size: 14px;
        margin-bottom: 20px;
        height: 50px;
        padding: 0;

        & + .button{
          margin-left: 0;
        }
      }

    }

    @include media(1280px){
      margin-top: 50px;
    }
    @include media(768px){
      flex-direction: column;
      align-items: center;
    }

  }
}



